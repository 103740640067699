<template>
  <div class="py-xxl relative m-auto flex flex-col items-center justify-center">
    <div class="z-1 px-xs relative text-center">
      <h1 class="m-0 block text-9xl font-bold leading-none md:text-6xl" v-bind="attributes?.title">
        <slot name="statusCode">{{ statusCode }}</slot>
        |
        <slot name="title">{{ title || t("error_page.title") }}</slot>
      </h1>

      <h2
        class="mt-s mb-m block text-4xl font-normal leading-normal md:text-2xl"
        v-bind="attributes?.subtitle"
      >
        <slot name="subtitle">{{ subtitle || t("error_page.subtitle") }}</slot>
      </h2>

      <UiButton v-bind="attributes?.cta as UiButtonProps" size="lg" :to="ctaTo || '/'">
        <slot name="ctaText">{{ ctaText || t("error_page.cta") }}</slot>
      </UiButton>
    </div>

    <slot name="statusCode" :statusCode="statusCode" />

    <slot />
  </div>
</template>

<script setup lang="ts">
import { UiButtonProps } from "@finq/ui/components/ui/Button.vue"
import { UiClassesToAttributes } from "@finq/ui/types/common"

interface ErrorPageProps {
  statusCode?: number
  title?: string
  subtitle?: string
  ctaText?: string
  ctaTo?: string
  attributes?: UiClassesToAttributes<{ title: any; subtitle: any; cta: any }>
}

const props = withDefaults(defineProps<ErrorPageProps>(), {
  statusCode: 404,
})

const { t } = useI18n({ useScope: "local" })
</script>

<i18n lang="json">
{
  "en": {
    "error_page": {
      "title": "Oh no!",
      "subtitle": "something went wrong…",
      "cta": "Take me home"
    }
  },
  "he": {
    "error_page": {
      "title": "אוי לא!",
      "subtitle": "נראה שמשהו השתבש…",
      "cta": "קח אותי הביתה"
    }
  }
}
</i18n>
